import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/images/black-logo.png';
import logoText from '../assets/images/black-text.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { AuthContext } from '../auth/AuthContext';
import { Menu, MenuItem } from '@mui/material';
import TemporaryDrawer from './SideDrawer';

function Navbar() {
  const { auth, setAuth } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const routeToDashboardHandler = () => {
    handleClose();
    navigate('/dashboard');
  }

  const routeToReportingHandler = () => {
    handleClose();
    navigate('/reports');
  }
  
  const logoutHandler = () => {
    handleClose();
    localStorage.removeItem('wellfit-authorization-jwt');
    localStorage.removeItem('wellfit-authorization-practitionerId');

    setAuth({
      auth: {
        isAuthenticated: false,
        practitionerId: '',
      },
      setAuth: null,
    });

    navigate('/login');
  }

  return (
    <div className='navbar'>
      <div className='left-nav'>
        <NavLink to='/'>
          <img src={logo} className='logo' alt='logo' />
        </NavLink>
        {/* <NavLink to='/' className="navbar-logo-text">
          <img src={logoText} className='logo-text' alt='logo' />
        </NavLink> */}
      </div>
      <ul className='right-nav'>
        <li className='nav-link massage'>
          <NavLink to='/profile/e2ebbbb8-5951-482d-a6ef-9c653e333d29' style={({ isActive }) => (isActive ? { color: '#98AFBA' } : {})}>
            Massage Therapy
          </NavLink>
        </li>

        {auth.isAuthenticated ? (
          <>
          <li className='nav-link login' id='demo-positioned-button' aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup='true' aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
            <FontAwesomeIcon icon={faUser} className="primary-color" />
            <span className="account"> Account</span>
          </li>
            <div>
              <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                style={{
                  top: '3rem',
                }}
              >
                <MenuItem onClick={routeToDashboardHandler}>Dashboard</MenuItem>
                <MenuItem onClick={routeToReportingHandler}>Reports</MenuItem>
                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
              </Menu>
            </div>
            <TemporaryDrawer/>
          </>
        ) : (
          <>
          <li className='nav-link login'>
            <NavLink to='/login' style={({ isActive }) => (isActive ? { color: '#98AFBA' } : {})}>
              <FontAwesomeIcon icon={faLock} color='#84D1F0' />
              <span color='#84D1F0'> Login</span>
            </NavLink>
          </li>
          <TemporaryDrawer/>
          </>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
