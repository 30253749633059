import React, { useContext, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { getPractitioner, getPractitionerBookings } from '../services/http.service';
import { AuthContext } from '../auth/AuthContext';
import Booking from '../components/Booking';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { inputFocusBorderColorOverride } from '../styles/mui-tsx-overrides';
import { createRef } from '@fullcalendar/core/preact';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function Dashboard() {
  const [date, setDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedBooking, setSelectedBooking]: any = React.useState({});
  const [practitioner, setPractitioner]: any = React.useState({});
  const { auth } = useContext(AuthContext)!;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [bookings, setBookings] = useState<any>(null);

  React.useEffect(() => {
    setWindowSize(getWindowSize());
    fetchPractitionerBookings();
  }, []);

  const fetchPractitioner = async () => {
    try {
      const results = await getPractitioner(auth?.practitionerId as string);
      setPractitioner(results?.data?.data);
    } catch (err: any) {
      console.log('ERROR: ', err);
    }
  };

  const fetchPractitionerBookings = async () => {
    try {
      const results = await getPractitionerBookings(auth?.practitionerId as string);
      const events = results?.data?.data?.map((booking: any) => {
        return {
          id: booking?.bookingId,
          title: booking?.isPaid ? ` ${booking?.nameOfBooker} | paid` : ` ${booking?.nameOfBooker} | awaiting payment`,
          date: booking?.startDate,
          start: booking?.startDate,
          end: booking?.endDate,
          isConfirmed: booking?.isConfirmed,
          isPaid: booking?.isPaid,
          service: booking?.service?.name,
          booking: booking,
          backgroundColor: booking?.isPaid ? 'hsl(138, 50%, 61%)' : 'rgb(48, 40, 140)',
          borderColor: booking?.isPaid ? 'hsl(138, 50%, 61%)' : 'rgb(48, 40, 140)',
          // backgroundColor: booking?.isPaid ? 'hsl(138, 50%, 61%)' : '#0d6efd',
          // borderColor: booking?.isPaid ? 'hsl(138, 50%, 61%)' : '#0d6efd'
        };
      });

      setBookings(events);

      return events;
    } catch (err: any) {
      console.log('ERROR: ', err);
    }
  };

  React.useEffect(() => {
    fetchPractitioner();
  }, []);

  const createBooking = () => {
    setOpen(true);
    setSelectedBooking(null);
  };

  const handleEventClick = (arg: any) => {
    setOpen(true);
    setSelectedBooking(arg?.event?.extendedProps?.booking);
  };

  const handleCloseEditor = () => {
    fetchPractitionerBookings();
    setOpen(false);
  };

  const handleDateChange = async (event: any) => {
    setDate(event?.$d);
    gotoDate(`${event?.$y}-${event?.$M + 1 < 10 ? `0${event?.$M + 1}` : event?.$M + 1}-${event?.$D}`);
  };

  const calendar = createRef();

  const gotoDate = (date: string) => {
    const calendarAPI = calendar.current.getApi();
    calendarAPI.gotoDate(date);
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <div className='event-card'>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <br></br>
        <p>{eventInfo.event?.extendedProps?.service}</p>
      </div>
    );
  };

  return (
    <div className='dashboard-container'>
      <div className='calendar-container'>
        <div className='calendar-header-container'>
          {/* <div className="blank-space">&nbsp;</div> */}
          <div className='calendar-date-picker-container'>
            <LocalizationProvider dateAdapter={AdapterDayjs} textFieldStyle={{ width: '100%' }}>
              <DatePicker label='Manual Date Selection' value={date} onChange={handleDateChange} renderInput={params => <TextField fullWidth id='text-field-date' {...params} sx={inputFocusBorderColorOverride} />} />
            </LocalizationProvider>
          </div>
          <div className='prac-name'>{practitioner?.name}</div>
          <div className='header-button-container'>
            <Button onClick={createBooking} id='primary-button' variant='contained'>
              <span className='button-text'>Create Booking</span>
            </Button>
          </div>
        </div>
        <div id="cal-container">
        <FullCalendar
          ref={calendar}
          plugins={[bootstrap5Plugin, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          initialView={windowSize?.innerWidth > 810 ? 'timeGridWeek' : 'timeGridDay'}
          weekends={false} // change to dynamic based on hours of operation
          headerToolbar={{
            left: 'prev next',
            center: 'title',
            right: 'timeGridWeek,timeGridDay',
            // right: windowSize?.innerWidth > 650 ? 'dayGridMonth,timeGridWeek' : 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          events={bookings}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          selectable={true}
          height='100%'
        />
        </div>
      </div>
      <Dialog TransitionComponent={Transition} open={open} onClose={handleCloseEditor} fullWidth={true} maxWidth={'lg'}>
        <Booking
          onClose={handleCloseEditor}
          type={selectedBooking?.practitionerId ? 'edit' : 'booking'}
          practitionerId={selectedBooking?.practitionerId ?? practitioner?.practitionerId}
          serviceId={selectedBooking?.serviceId ?? practitioner?.serviceId}
          duration={selectedBooking?.timeBlock?.unitsOfTime}
          currentDate={selectedBooking?.startDate?.slice(0, 10)}
          bookingId={selectedBooking?.bookingId}
          startDate={selectedBooking?.startDate}
          name={selectedBooking?.nameOfBooker}
          email={selectedBooking?.emailOfBooker}
          phone={selectedBooking?.phoneNumberOfBooker}
          promoCodeObject={selectedBooking?.promoCode}
          isPaid={selectedBooking?.isPaid}
        />
        {/* use for emit method from booking <Button onClick={handleClose}>Subscribe</Button> */}
      </Dialog>
    </div>
  );
}

export default Dashboard;

// useful future calendar methods & hooks

// const calendarRef: any = React.createRef();
// const handleDateClick = (arg: any) => {
//     console.log(arg.dateStr);
// };
// const handleDateSelect = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };
// const handleEventAdd = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };
// const handleEventChange = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };
// const handleEventRemove = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };
// const handleEvents = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };
// const handleMonthChange = (arg: any) => {
//     console.log(JSON.stringify(arg));
// };

// ref={calendarRef}
// dateClick={handleDateClick}
// selectMirror={true}
// editable={true}
// select={handleDateSelect}
// eventAdd={handleEventAdd}
// eventChange={handleEventChange}
// eventRemove={handleEventRemove}
// eventsSet={handleEvents}
// datesSet={handleMonthChange}
