import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { inputFocusBorderColorOverride, selectFocusBorderColorOverride, checkboxCheckedBorderColorOverride } from '../styles/mui-tsx-overrides';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getPractitioner, getPractitioners, getPractitionerAvailableTimes, createBooking, updateBooking, deleteBooking, fetchPaymentLinkUrl, sendReceiptOrInvoiceToBooker } from '../services/http.service';
import Loader from './Loader';
import BookingCost from './BookingCost';
import { AlertContext } from '../errors/AlertContext';

dayjs.extend(advancedFormat);

interface AdminButtonProps {
  text: string;
  show: boolean;
  color: string;
  method: Function;
}

function AdminButton(props: AdminButtonProps) {
  if (!props.show) {
    return null;
  }

  const colorMap: any = {
    blue: 'admin-button admin-button--blue',
    green: 'admin-button admin-button--green',
    yellow: 'admin-button admin-button--yellow',
  }

  return <button className={colorMap[props.color]} onClick={() => props.method()}>{props.text || 'ADMIN BUTTON'}</button>;
}

function Booking(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
  const { setAlert } = useContext(AlertContext)!;

  const setAlertHandler = (message: string) => {
    setAlert({
      alertText: message || 'Something went Wrong!',
      alertType: 'error',
    });

    setTimeout(() => {
      setAlert({
        alertText: '',
        alertType: '',
      });
    }, 6000);
  };

  const fetchPractitioner = async (practitionerId: string, serviceId: string, duration: string, currentDate: string, bookingId: string, startDate: string, name: string, email: string, phone: string) => {
    try {
      const results = await getPractitioner(practitionerId);
      setPractitioner(results?.data?.data);

      if (props?.type === 'profile' || props?.type === 'booking' || props?.type === 'edit') {
        setPractitionerOptions([
          {
            value: results?.data?.data?.practitionerId,
            name: results?.data?.data?.name,
          },
        ]);
      }
      setSelectedPractitioner(results?.data?.data?.practitionerId);
      setServiceOptions(
        results?.data?.data?.services?.map((service: any) => {
          return {
            value: service?.id,
            name: service?.name,
          };
        })
      );
      let currentService: any = [];
      if (serviceId) {
        setSelectedService(serviceId);
        results?.data?.data?.services?.forEach((service: any) => {
          if (service?.id === serviceId) {
            currentService = service;
          }
        });
      }

      if (results?.data?.data?.services?.length === 1) {
        setSelectedService(results?.data?.data?.services[0]?.id);
        currentService = results?.data?.data?.services[0];
      }

      if (currentService) {
        setDurationOptions(
          currentService?.timeBlocks
            ?.sort((a: any, b: any) => a?.unitsOfTime - b?.unitsOfTime)
            .map((block: any) => {
              return {
                value: block?.unitsOfTime,
                name: block?.name,
                id: block?.id,
                cost: block?.cost,
              };
            })
        );
      }

      if (duration) {
        setSelectedDurationCost(duration);
      }

      if (currentService?.timeBlocks?.length === 1) {
        setSelectedDurationCost(currentService?.timeBlocks[0]?.unitsOfTime);
        setBookingCost(currentService?.timeBlocks[0]?.cost);
      }

      if (currentDate) {
        setDate(dayjs(currentDate));
        setNextSelected(false);
        if (duration) {
          if (bookingId) {
            setLoading(true);
            const results = await getPractitionerAvailableTimes(practitionerId, duration, currentDate, bookingId);

            if (
              date
                ?.set('hours', 0)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0)
                .isSame(dayjs(new Date(Date.now())).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))
            ) {
              setTimeOptions(
                results?.data?.data[0]?.availableTimes.filter((el: any) => {
                  return dayjs(new Date(el.startDate)).isAfter(dayjs(new Date(Date.now())));
                })
              );
            } else {
              setTimeOptions(results?.data?.data[0]?.availableTimes);
            }

            setLoading(false);
            if (startDate) {
              results?.data?.data[0]?.availableTimes?.forEach((time: any) => {
                if (time?.startDate === startDate) {
                  setSelectedTime(time);
                }
              });
            }
          } else {
            setLoading(true);
            const results = await getPractitionerAvailableTimes(practitionerId, duration, currentDate);

            if (
              date
                ?.set('hours', 0)
                .set('minutes', 0)
                .set('seconds', 0)
                .set('milliseconds', 0)
                .isSame(dayjs(new Date(Date.now())).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))
            ) {
              setTimeOptions(
                results?.data?.data[0]?.availableTimes.filter((el: any) => {
                  return dayjs(new Date(el.startDate)).isAfter(dayjs(new Date(Date.now())));
                })
              );
            } else {
              setTimeOptions(results?.data?.data[0]?.availableTimes);
            }

            setLoading(false);
          }
        }
      } else {
        console.log('😂', "RUNNING THIS FUCKING ONE")
        if (duration) {
          setLoading(true);
          const results = await getPractitionerAvailableTimes(practitionerId, duration);
          setNextOptions(results?.data?.data?.map((item: any) => item?.date));
          setLoading(false);
        }
      }

      if (name) {
        setFullName(name);
      }

      if (email) {
        setEmail(email);
      }

      if (phone) {
        setPhone(phone);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchPractitioners = async () => {
    try {
      const results = await getPractitioners();
      setPractitionerOptions(
        results?.data?.data
          ?.filter((practitioner: any) => practitioner?.practitionerTypeId === props?.practitionerTypeId)
          .map((p: any) => {
            return {
              value: p?.practitionerId,
              name: p?.name,
            };
          })
      );
      if (props?.practitionerId) {
        setSelectedPractitioner(props?.practitionerId);
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    if (props?.practitionerId) {
      if (props?.type === 'profile' || props?.type === 'booking' || props?.type === 'edit') {
        fetchPractitioner(props?.practitionerId, props?.serviceId, props?.duration, props?.currentDate, props?.bookingId, props?.startDate, props?.name, props?.email, props?.phone);
      } else {
        fetchPractitioners();
      }
    } else {
      fetchPractitioners();
    }
  }, [props?.practitionerId]);
  const [practitioner, setPractitioner] = React.useState({});
  const [selectedPractitioner, setSelectedPractitioner] = React.useState('');
  const [practitionerOptions, setPractitionerOptions] = React.useState([{}]);
  const [selectedService, setSelectedService] = React.useState('');
  const [serviceOptions, setServiceOptions] = React.useState([{}]);
  const [selectedDurationCost, setSelectedDurationCost] = React.useState('');
  const [durationOptions, setDurationOptions] = React.useState([{}]);
  const [bookingCost, setBookingCost] = React.useState('');
  const [nextSelected, setNextSelected] = React.useState(true);
  const [nextOptions, setNextOptions] = React.useState(['']);
  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [timeOptions, setTimeOptions] = React.useState([{}]);
  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [disabledButtons, setDisabledButtons] = React.useState(false);
  const [promoCodeId, setPromoCodeId] = useState<number | null>(null);

  useEffect(() => {
    const cost: any = durationOptions.find((el: any) => el.value == selectedDurationCost);

    if (cost) {
      setBookingCost(cost.cost);
    }
  }, [selectedDurationCost]);

  useEffect(() => {
    if (props.promoCodeObject) {
      setPromoCodeId(props.promoCodeObject.id);
    }
  }, []);

  const handlePractitionerChange = (event: SelectChangeEvent) => {
    setSelectedPractitioner(event.target.value);
  };

  const handleServiceChange = (event: SelectChangeEvent) => {
    setSelectedService(event.target.value);
  };

  const handleDurationChange = async (event: SelectChangeEvent) => {
    setSelectedDurationCost(event.target.value);

    const cost: any = durationOptions.find((el: any) => el.value === event.target.value);

    if (cost) {
      setBookingCost(cost.cost);
    }
    if (!date) {
      setLoading(true);
      const results = await getPractitionerAvailableTimes(selectedPractitioner, event.target.value);
      setNextOptions(results?.data?.data?.map((item: any) => item?.date));
      setLoading(false);
    } else {
      let results;

      if (props?.bookingId) {
        let currentDate: any = date;
        const dateString = `${currentDate?.$y}-${currentDate?.$M + 1}-${currentDate?.$D}`;
        setLoading(true);
        results = await getPractitionerAvailableTimes(selectedPractitioner, event.target.value, dateString, props?.bookingId);
      } else {
        let currentDate: any = date;
        const dateString = `${currentDate?.$y}-${currentDate?.$M + 1}-${currentDate?.$D}`;
        setLoading(true);
        results = await getPractitionerAvailableTimes(selectedPractitioner, event.target.value, dateString);
      }

      if (
        date
          ?.set('hours', 0)
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0)
          .isSame(dayjs(new Date(Date.now())).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))
      ) {
        setTimeOptions(
          results?.data?.data[0]?.availableTimes.filter((el: any) => {
            return dayjs(new Date(el.startDate)).isAfter(dayjs(new Date(Date.now())));
          })
        );
      } else {
        setTimeOptions(results?.data?.data[0]?.availableTimes);
      }

      setLoading(false);
    }
  };

  const handleNextChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setNextSelected(event.target.checked);
    setSelectedTime(null);
    setTimeOptions([{}]);
    if (event.target.checked) {
      setDate(null);
      setLoading(true);
      const results = await getPractitionerAvailableTimes(selectedPractitioner, selectedDurationCost);
      setNextOptions(results?.data?.data?.map((item: any) => item?.date));
      setLoading(false);
    }
  };

  const resetDiscountHandler = () => {
    setPromoCodeId(null);
  };

  const handleDateChange = async (event: any) => {
    let date = null;
    let dateString = '';
    if (event?.$d) {
      date = dayjs(event?.$d);
      dateString = `${event?.$y}-${event?.$M + 1}-${event?.$D}`;
    }

    if (event?.target?.innerHTML) {
      date = dayjs(new Date(event?.target?.innerHTML));
      dateString = date.format('YYYY-MM-DD');
    }
    setNextSelected(false);
    setDate(dayjs(date));
    setLoading(true);
    const results = await getPractitionerAvailableTimes(selectedPractitioner, selectedDurationCost, dateString);
    console.log('😂', results.data.data);
    if (
      date
        ?.set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .set('milliseconds', 0)
        .isSame(dayjs(new Date(Date.now())).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0))
    ) {
      setTimeOptions(
        results?.data?.data[0]?.availableTimes.filter((el: any) => {
          return dayjs(new Date(el.startDate)).isAfter(dayjs(new Date(Date.now())));
        })
      );
    } else {
      setTimeOptions(results?.data?.data[0]?.availableTimes);
    }

    setLoading(false);
  };

  const handleTimeChange = (event: any) => {
    timeOptions?.forEach((time: any) => {
      console.log('😂', time);
      if (time?.display === event?.target?.innerHTML) {
        console.log('😂😂', time);
        setSelectedTime(time);
      }
    });
  };

  const handleNameChange = (e: any) => {
    const { value } = e.target;
    setFullName(value);
  };

  const handleEmailChange = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handlePhoneChange = (e: any) => {
    const { value } = e.target;
    setPhone(value);
  };

  const submitBooking = async () => {
    setDisabledButtons(true);
    setLoadingResponse(true);
    const time: any = selectedTime;
    const prac: any = practitioner;
    let blockId = '';
    durationOptions?.forEach((option: any) => {
      if (option?.value === +selectedDurationCost) {
        blockId = option?.id;
      }
    });

    const payload = {
      startDate: time?.startDate,
      endDate: time?.endDate,
      name: fullName,
      email,
      phoneNumber: phone,
      practitionerId: selectedPractitioner,
      serviceId: selectedService,
      timeBlockId: blockId,
      spaceId: prac?.space?.id,
      promoCodeId: promoCodeId,
    };

    try {
      const results = await createBooking(payload);
      setLoadingResponse(false);

      setAlert({
        alertText: 'Appointment Booked Successfully!',
        alertType: 'success',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
        setSelectedDurationCost('');
        setNextSelected(true);
        setDate(null);
        setSelectedTime(null);
        setFullName('');
        setEmail('');
        setPhone('');
        setPromoCodeId(null);
      }, 4000);

      if (props?.onClose) {
        setTimeout(() => {
          props?.onClose();

          setSelectedDurationCost('');
          setNextSelected(true);
          setDate(null);
          setSelectedTime(null);
          setFullName('');
          setEmail('');
          setPhone('');
        }, 2000);
      }
    } catch (err: any) {
      setAlert({
        alertText: err?.response?.data?.message || 'Something went Wrong!',
        alertType: 'error',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);
    } finally {
      setDisabledButtons(false);
      setLoadingResponse(false);
      if (props?.onClose) {
        // props?.onClose();
      }
    }
  };

  const changeBooking = async () => {
    setLoadingResponse(true);
    const time: any = selectedTime;
    const prac: any = practitioner;
    let blockId = '';
    durationOptions?.forEach((option: any) => {
      if (option?.value === +selectedDurationCost) {
        blockId = option?.id;
      }
    });

    const payload = {
      startDate: time?.startDate,
      endDate: time?.endDate,
      name: fullName,
      email,
      phoneNumber: phone,
      practitionerId: selectedPractitioner,
      serviceId: selectedService,
      timeBlockId: blockId,
      spaceId: prac?.space?.id,
      promoCodeId: promoCodeId,
    };

    try {
      const results = await updateBooking(payload, props?.bookingId);
      setLoadingResponse(false);

      setAlert({
        alertText: 'Appointment Updated Successfully!',
        alertType: 'success',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);

      if (props?.onClose) {
        setTimeout(() => {
          props?.onClose();
        }, 2000);
      }
    } catch (err: any) {
      setAlert({
        alertText: err?.response?.data?.message || 'Something went Wrong!',
        alertType: 'error',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);
    } finally {
      setDisabledButtons(false);
      setLoadingResponse(false);
      setPromoCodeId(null);
    }
  };

  const cancelBooking = async () => {
    try {
      setLoadingResponse(true);
      const results = await deleteBooking(props?.bookingId);
      setLoadingResponse(false);

      setAlert({
        alertText: 'Appointment Cancelled Successfully!',
        alertType: 'success',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);

      if (props?.onClose) {
        setTimeout(() => {
          props?.onClose();
        }, 2000);
      }
    } catch (err: any) {
      setAlert({
        alertText: err?.response?.data?.message || 'Something went Wrong!',
        alertType: 'error',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);
    } finally {
      setLoadingResponse(false);
      setDisabledButtons(false);
      setPromoCodeId(null);
    }
  };
  
  async function navigateToPaymentPortal(bookingId: string) {
    try {
      const data: any = await fetchPaymentLinkUrl(bookingId);
      const paymentLink: string = data.data.data;

      if (!paymentLink) {
        throw new Error();
      }

      window.location.href = paymentLink;
    } catch (err: any) {
      setAlert({
        alertText: "Oops! It seems like we can't find the payment URL.",
        alertType: "error"
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: ""
        });
      }, 6000);
    }
  }

  async function sendReceiptOfInvoice(bookingId: string) {
    try {
      await sendReceiptOrInvoiceToBooker(bookingId);

      setAlert({
        alertText: "Successfully sent to booker!",
        alertType: "success"
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: ""
        });
      }, 6000);
    } catch (err: any) {
      setAlert({
        alertText: "Oops! There was an issue sending document.",
        alertType: "error"
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: ""
        });
      }, 6000);
    }
  }

  function TimeSlots() {
    if (!timeOptions || timeOptions.length === 0) {
      return <div className='booking__no-appointments'>No Available Appointments.</div>;
    }

    return (
      <>
        {timeOptions.map((time: any, i: number) => (
          <div className={time === selectedTime ? 'active-time-chip' : 'time-chip'} key={i} onClick={handleTimeChange}>
            {time?.display}
          </div>
        ))}
      </>
    );
  }

  return (
    <>
    {props?.type === 'edit' && (
      <div className="booking-header">
        <div className="admin-controls">
          <h1>Practitioner Controls</h1>
          <div className="button-group">
            <AdminButton color="green" text="Pay Booking" method={() => navigateToPaymentPortal(props.bookingId)} show={!props.isPaid} />
            <AdminButton color="blue" text={props.isPaid ? "Send Receipt" : "Send Invoice"} method={() => sendReceiptOfInvoice(props.bookingId)} show={true} />
          </div>
        </div>
      </div>
    )}
      <div className='booking-card'>
        {loadingResponse ? (
          <div className='booking-card__loader-container'>
            <Loader />
          </div>
        ) : null}
        <div className='booking-card-left'>
          <div className='booking__section-title'>Choose a Service</div>
          <div className='w100 mb20 tal'>
            <FormControl fullWidth>
              <InputLabel id='practitioner-label' required>
                Practitioner
              </InputLabel>
              <Select
                disabled={props?.type === 'profile' || practitionerOptions?.length === 1 || props?.isPaid}
                labelId='practitioner-label'
                id='select-field'
                value={selectedPractitioner}
                label='Practitioner'
                onChange={handlePractitionerChange}
                sx={selectFocusBorderColorOverride}
              >
                {practitionerOptions.map((practitioner: any, i: number) => (
                  <MenuItem key={i} value={practitioner?.value}>
                    {practitioner?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='w100 mb20 tal'>
            <FormControl fullWidth>
              <InputLabel id='service-label' required>
                Service
              </InputLabel>
              <Select disabled={serviceOptions?.length === 1 || props?.isPaid} labelId='service-label' id='select-field' value={selectedService} label='Service' onChange={handleServiceChange} sx={selectFocusBorderColorOverride}>
                {serviceOptions.map((service: any, i: number) => (
                  <MenuItem key={i} value={service?.value}>
                    {service?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='booking__section-title'>Choose Appointment Length</div>
          <div className='w100 mb20 tal'>
            <FormControl fullWidth>
              <InputLabel id='duration-label' required>
                Duration
              </InputLabel>
              <Select disabled={props?.isPaid} labelId='duration-label' id='select-field' value={selectedDurationCost} label='Duration' onChange={handleDurationChange} sx={selectFocusBorderColorOverride}>
                {durationOptions.map((duration: any, i: number) => (
                  <MenuItem key={i} value={duration?.value}>
                    {duration?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='booking-card-right'>
          <div className='booking__section-title'>Provide your Information</div>
          <div className='w100 mb20 tal'>
            <TextField id='text-field' label='Full Name' value={fullName} onChange={handleNameChange} fullWidth required sx={inputFocusBorderColorOverride} />
          </div>
          <div className='w100 mb20 tal'>
            <TextField id='text-field' label='Email' value={email} onChange={handleEmailChange} fullWidth required sx={inputFocusBorderColorOverride} />
          </div>
          <div className='w100 mb20 tal'>
            <TextField id='text-field' label='Phone Number' value={phone} onChange={handlePhoneChange} fullWidth required sx={inputFocusBorderColorOverride} />
          </div>
        </div>
        {
          props?.type === 'edit' && props.isPaid && (
            <>
              <div className='is-paid-message'>
                WARNING: You cannot make changes to a booking that will effect the price if it has already been paid.
              </div>
            </>
          )
        }
        <div className='booking-card-bottom'>
          <div className='booking__section-title'>Choose a Date</div>
          {selectedDurationCost ? (
            <FormControlLabel
              className='booking__available-date-checkbox'
              control={<Checkbox checked={nextSelected} onChange={handleNextChange} inputProps={{ 'aria-label': 'controlled' }} sx={checkboxCheckedBorderColorOverride} />}
              label='Next Available Dates'
            />
          ) : (
            ''
          )}
          {selectedDurationCost ? (
            nextSelected ? (
              <div className='next-container'>
                {nextOptions.map((next: any, i: number) => (
                  <div className='next-date' key={i} onClick={handleDateChange}>
                    {dayjs(next).format('MMMM DD, YYYY')}
                  </div>
                ))}
              </div>
            ) : (
              <div className='date-picker-container'>
                <LocalizationProvider dateAdapter={AdapterDayjs} textFieldStyle={{ width: '100%' }}>
                  <DatePicker label='Manual Date Selection' value={date} onChange={handleDateChange} renderInput={params => <TextField id='text-field' fullWidth {...params} sx={inputFocusBorderColorOverride} />} />
                </LocalizationProvider>
              </div>
            )
          ) : (
            ''
          )}
          {date ? <div className='times-container'>{loading ? <Loader /> : <TimeSlots />}</div> : ''}
          {
            (props?.type === 'profile' || props?.type === 'booking') && selectedPractitioner && selectedService && selectedDurationCost && date && selectedTime && fullName && email && phone && (
              <>
                <div className='booking__section-title'>Apply a Promo Code</div>
                <BookingCost
                  promoCodeValue={props?.promoCodeObject?.value}
                  promoCodeModifier={props?.promoCodeObject?.modifier}
                  onSuccess={(promoCodeId: number) => setPromoCodeId(promoCodeId)}
                  onError={(message: string) => setAlertHandler(message)}
                  onReset={() => resetDiscountHandler()}
                  practitionerId={selectedPractitioner}
                  type='profile'
                  cost={bookingCost}
                />
                <div className='button-container'>
                  <Button onClick={submitBooking} disabled={disabledButtons} id='primary-button' variant='contained'>
                    <span className='button-text'>Submit Booking</span>
                  </Button>
                </div>
              </>
            )
            // </div>
          }
          {
            props?.type === 'edit' && (
              <>
                {!props.isPaid &&
                  <>
                    <div className='booking__section-title'>Apply a Promo Code</div>
                    <BookingCost
                      promoCodeValue={props?.promoCodeObject?.value}
                      promoCodeModifier={props?.promoCodeObject?.modifier}
                      onSuccess={(promoCodeId: number) => setPromoCodeId(promoCodeId)}
                      onError={(message: string) => setAlertHandler(message)}
                      onReset={() => resetDiscountHandler()}
                      practitionerId={selectedPractitioner}
                      type='edit'
                      cost={bookingCost}
                    />
                  </>
                }
                <div className='button-container'>
                  {!props.isPaid &&
                    <Button onClick={cancelBooking} id='error-button' variant='contained'>
                      <span className='button-text'>Delete Booking</span>
                    </Button>
                  }
                  {selectedPractitioner && selectedService && selectedDurationCost && date && selectedTime && fullName && email && phone && (
                    <>
                      <Button onClick={changeBooking} id='success-button' variant='contained'>
                        <span className='button-text'>Update Booking</span>
                      </Button>
                    </>
                  )}
                </div>
              </>
            )
            // </div>
          }
        </div>
      </div>
    </>
  );
}

export default Booking;
