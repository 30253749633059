import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Reports from './pages/Reports';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

import './App.scss';
import './styles/colors.scss';
import PrivateRoutes from './auth/PrivateRoute';
import { AuthContext } from './auth/AuthContext';
import { AlertContext } from './errors/AlertContext';
import { useState } from 'react';
import { Alert, AlertColor } from '@mui/material';

function App() {
  const practitioner = localStorage.getItem('wellfit-authorization-practitionerId');

  const [auth, setAuth] = useState<{
    isAuthenticated: boolean;
    practitionerId: string;
  }>(
    practitioner
      ? {
          isAuthenticated: true,
          practitionerId: practitioner,
        }
      : {
          isAuthenticated: false,
          practitionerId: '',
        }
  );

  const [alert, setAlert] = useState<{
    alertText: string;
    alertType: AlertColor | undefined;
  }>({
    alertText: '',
    alertType: undefined,
  });

  return (
    <div className='App'>
      <main className='main'>
        <BrowserRouter>
          <AlertContext.Provider value={{ alert, setAlert }}>
            {alert.alertText && alert.alertType ? (
              <Alert variant='filled' className='alert' severity={alert.alertType}>
                <div className='alert__message'>{alert.alertText}</div>
              </Alert>
            ) : null}
            <AuthContext.Provider value={{ auth, setAuth }}>
              <Navbar />
              <div className='content-container'>
                <Routes>
                  <Route element={<PrivateRoutes />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/reports' element={<Reports />} />
                  </Route>
                  <Route path='/' element={<Navigate to='/home' />} />
                  <Route path='/home' element={<Home />} />
                  <Route path='/profile/megan-tsai' element={<Navigate to={'/profile/e2ebbbb8-5951-482d-a6ef-9c653e333d29'} />} />
                  <Route path='/profile/:practitionerId' element={<Profile />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/login' element={<Login />} />
                </Routes>
              </div>
              <Footer />
            </AuthContext.Provider>
          </AlertContext.Provider>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
