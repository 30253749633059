import { AlertColor } from "@mui/material";
import { createContext } from "react";

interface IAlertContext {
  alert: {
    alertText: string;
    alertType: AlertColor | undefined;
  },
  setAlert: any;
}

export const AlertContext = createContext<IAlertContext>({
  alert: {
    alertText: '',
    alertType: 'error',
  },
  setAlert: null,
});