import { createContext } from "react";

interface IAuthContext {
  auth: {
    isAuthenticated: boolean;
    practitionerId: string;
  };
  setAuth: any;
}

export const AuthContext = createContext<IAuthContext>({
  auth: {
    isAuthenticated: false,
    practitionerId: '',
  },
  setAuth: null,
});