import payments from '../assets/images/payments.png';
import megan from '../assets/images/megan.jpeg';
import Booking from '../components/Booking';

function Profile() {

    return (
        <div className='page-container'>
            <div className='profile-container'>
                <div className='profile'>
                    <div className='profile-left'>
                        <div className='profile-picture-container'>
                            <img src={megan} className='profile-picture' alt='practitioner' />
                        </div>
                    </div>
                    <div className='profile-right'>
                        <div className='profile-name'>Megan Tsai</div>
                        <div className='profile-title'>Registered Massage Therapist</div>
                    </div>
                </div>
                <img src={payments} className='payments' alt='payment types' />
                <div className='profile-bio'>For over 10 years, Megan has the passion for the therapeutic massage in holistic approach by tailoring the treatment according to the individuals through combination of modalities as the deep tissue, TCM body work, reflexology, Hot stone to integrate the treatment.</div>
                <div className='profile-address'>122 Laird Dr, East York ON, M4G 3V3, Unit 205</div>
            </div>
            <div className='services'>
                <div className='services-title'>Available Services</div>
                <div className='services-card'>
                    <div className='service-title'>Massage Therapy</div>
                    <div className='service-description'>A therapeutic massage tailored to the individual through a combination of deep tissue, TCM body work, reflexology, & Hot stone treatments.</div>
                    <div className='service-time-cost-container'>
                        <div className='service-time-cost'>
                            <div className='service-time'>30 Minutes</div>
                            <div className='service-cost'>$90 +HST</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>45 Minutes</div>
                            <div className='service-cost'>$115 +HST</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>60 Minutes</div>
                            <div className='service-cost'>$135 +HST</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>75 Minutes</div>
                            <div className='service-cost'>$165 +HST</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>90 Minutes</div>
                            <div className='service-cost'>$195 +HST</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='services'>
                <div className='services-title'>Hours of Operation</div>
                <div className='services-card'>
                    <div className='service-time-cost-container'>
                        <div className='service-time-cost'>
                            <div className='service-time'>Monday</div>
                            <div className='service-cost'>OFF</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>Tuesday</div>
                            <div className='service-cost'>10AM - 8PM</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>Wednesday</div>
                            <div className='service-cost'>OFF</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>Thursday</div>
                            <div className='service-cost'>10AM - 8PM</div>
                        </div>
                        <div className="service-spacer">&nbsp;</div>
                        <div className='service-time-cost'>
                            <div className='service-time'>Friday</div>
                            <div className='service-cost'>10AM - 8PM</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='booking'>
                <div className='services-title'>Book an Appointment</div>
                <div className='booking-sub-container'>
                    <Booking type="profile" practitionerId="e2ebbbb8-5951-482d-a6ef-9c653e333d29" practitionerTypeId="" serviceId="6d21267b-45ac-42ee-b2dc-b005457d58c9" duration="4" currentDate="" bookingId="" startDate="" name="" email="" phone="" />
                </div>
                {/* <Booking type="booking" practitionerId="e2ebbbb8-5951-482d-a6ef-9c653e333d29" practitionerTypeId="1" serviceId="6d21267b-45ac-42ee-b2dc-b005457d58c9" duration="4" currentDate="2023-01-01" bookingId="08169e00-7fc9-41e3-a870-b42516082af0" startDate="2023-01-01 12:30" name="Bret McGee" email="bret.mcgee15@gmail.com" phone="2892448135" /> */}
            </div>
        </div>
    )
}

export default Profile;