export const inputFocusBorderColorOverride = {
    "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
            borderColor: '#5cbfed'
            // borderColor: '#a8d6ad'
        }
    }
}

export const fullWidthOverride = {
    "fullWidth": "100%",
}

export const selectFocusBorderColorOverride = {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#5cbfed',
    }
}


export const checkboxCheckedBorderColorOverride = {
    '&.Mui-checked': {
      color: '#5cbfed',
    },
}

// select full override
// sx={{
//   color: "white",
//   '.MuiOutlinedInput-notchedOutline': {
//     borderColor: 'rgba(228, 219, 233, 0.25)',
//   },
//   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     borderColor: 'rgba(228, 219, 233, 0.25)',
//   },
//   '&:hover .MuiOutlinedInput-notchedOutline': {
//     borderColor: 'rgba(228, 219, 233, 0.25)',
//   },
//   '.MuiSvgIcon-root ': {
//     fill: "white !important",
//   }
// }}