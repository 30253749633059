import '../styles/home.scss';
import Booking from '../components/Booking';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { betaUserSignUp } from '../services/http.service';
import { AlertContext } from '../errors/AlertContext';

function Home() {
  const navigate = useNavigate();
  const routeToMegan = () => navigate('/profile/megan-tsai');

  const [email, setEmail] = useState<string>('');
  const { alert, setAlert } = useContext(AlertContext)

  const submitHandler = async (e: any) => {
    e.preventDefault();

    if (alert.alertText) {
      return;
    }

    const results: any = await betaUserSignUp(email);

    if (results.data.error) {
      setAlert({
        alertText: results.data.error,
        alertType: 'error',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 6000);
      
      return;
    }

    setAlert({
      alertText: 'You have successfully registered!',
      alertType: 'success',
    });

    setEmail('');

    setTimeout(() => {
      setAlert({
        alertText: '',
        alertType: '',
      });
    }, 6000);    
  }

  return <div className="home-container">
    <section className="main-landing">
      <div className="title">WellFit Gyms.</div>
      <div className="sub-title">A re-imagined wellness experience coming soon</div>
      <div className="secondary-heading">RSVP for our Grand Opening party and early access</div>
      <form className="main-landing__form" onSubmit={submitHandler}>
        <input placeholder='example@email.com' type="text" className="main-landing__input" value={email} onChange={e => setEmail(e.target.value)} />
        <div className="call-to-action" onClick={e => submitHandler(e)}>Notify Me</div>
      </form>
    </section>
    {/* <section>
      <div className="secondary-heading">About Us</div>
      <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum dicta repudiandae reiciendis, ratione consequatur necessitatibus nam blanditiis tempore optio delectus animi, ea, vel nulla dolorem earum? Assumenda fuga labore sint? Asperiores rem est, officiis similique et quod alias suscipit fugit nesciunt nisi recusandae soluta at facilis maiores sint molestias aspernatur ab aliquam reprehenderit dicta. Ea necessitatibus, a beatae distinctio quae, debitis ipsum ipsam itaque eveniet nam eligendi porro provident facere consectetur deleniti. Dolor quo impedit a accusamus commodi, sapiente doloribus, itaque soluta ipsam molestias nisi cupiditate minus?</div>
      <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum dicta repudiandae reiciendis, ratione consequatur necessitatibus nam blanditiis tempore optio delectus animi, ea, vel nulla dolorem earum? Assumenda fuga labore sint? Asperiores rem est, officiis similique et quod alias suscipit fugit nesciunt nisi recusandae soluta at facilis maiores sint molestias aspernatur ab aliquam reprehenderit dicta. Ea necessitatibus, a beatae distinctio quae, debitis ipsum ipsam itaque eveniet nam eligendi porro provident facere consectetur deleniti. Dolor quo impedit a accusamus commodi, sapiente doloribus, itaque soluta ipsam molestias nisi cupiditate minus?</div>
    </section>
    <section>
      <div className="secondary-heading">Our Services</div>
      <div className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quae commodi, magnam aspernatur placeat explicabo aperiam saepe maiores unde. Adipisci totam minus tempore quos ipsum sequi, facere quaerat? Praesentium, velit!</div>
    </section>
    <section className="cards">
      <div className="cards__card cards__card--offset">
        <FontAwesomeIcon className="cards__card__icon" icon={faSpa} />
        <div className="cards__card__title">Immersive Yoga</div>
        <div className="cards__card__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum laudantium nam minima, porro neque harum expedita beatae perspiciatis autem deserunt quae est voluptas quis aut maxime dolore, praesentium consequatur! Omnis numquam fugiat libero totam iste eos consequuntur quidem! Minima nostrum ex sed soluta obcaecati laborum laboriosam ipsa cumque in sit?</div>
      </div>
      <div className="cards__card">
        <FontAwesomeIcon className="cards__card__icon" icon={faHouseCircleCheck} />
        <div className="cards__card__title">Premium Fitness Space Rentals</div>
        <div className="cards__card__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum laudantium nam minima, porro neque harum expedita beatae perspiciatis autem deserunt quae est voluptas quis aut maxime dolore, praesentium consequatur! Omnis numquam fugiat libero totam iste eos consequuntur quidem! Minima nostrum ex sed soluta obcaecati laborum laboriosam ipsa cumque in sit?</div>
      </div>
      <div className="cards__card cards__card--offset">
        <FontAwesomeIcon className="cards__card__icon" icon={faStethoscope} />
        <div className="cards__card__title">Wellness Office Rentals</div>
        <div className="cards__card__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum laudantium nam minima, porro neque harum expedita beatae perspiciatis autem deserunt quae est voluptas quis aut maxime dolore, praesentium consequatur! Omnis numquam fugiat libero totam iste eos consequuntur quidem! Minima nostrum ex sed soluta obcaecati laborum laboriosam ipsa cumque in sit?</div>
      </div>
    </section> */}
    <section>
      <div className="secondary-heading mb20">Intereseted in our RMT service?</div>
      <div className="secondary-heading secondary-heading--light">Book with <span onClick={routeToMegan} className='secondary-heading__link'>Megan Tsai</span></div>
      <Booking type="booking" practitionerId="e2ebbbb8-5951-482d-a6ef-9c653e333d29" serviceId="6d21267b-45ac-42ee-b2dc-b005457d58c9" duration="4" />
    </section>
    {/* <section>
      <div className="secondary-heading">Disclaimer</div>
      <div className="description description__italic">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quae commodi, magnam aspernatur placeat explicabo aperiam saepe maiores unde. Adipisci totam minus tempore quos ipsum sequi, facere quaerat? Praesentium, velit!</div>
    </section> */}
  </div>;
}

export default Home;
