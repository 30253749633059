import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { checkPromoCode } from '../services/http.service';
import { inputFocusBorderColorOverride } from '../styles/mui-tsx-overrides';

interface Props {
  cost: string;
  type: 'booking' | 'edit' | 'profile';
  practitionerId: string;
  promoCodeValue: string | undefined;
  promoCodeModifier: number | undefined;
  onError: (message: string) => void;
  onSuccess: (promoCodeId: number) => void;
  onReset: () => void;
}

function BookingCost(props: Props) {
  const hst: number = 1.13;
  const [promoCode, setPromoCode] = useState<string>('');
  const [appliedDiscount, setAppliedDiscount] = useState<number | null>(null);

  useEffect(() => {
    if (props.promoCodeModifier) {
      setAppliedDiscount(props.promoCodeModifier);
    }

    if (props.promoCodeValue) {
      setPromoCode(props.promoCodeValue);
    }
  }, []);

  const removeDiscountHandler = () => {
    setPromoCode('');
    setAppliedDiscount(null);
    props.onReset();
  };

  const submitPromoCodeHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const results: any = await checkPromoCode(props.practitionerId, promoCode);

      setAppliedDiscount(results?.data?.data.modifier);
      props.onSuccess(results?.data?.data.promoCodeId);
    } catch(err: any) {
      props.onError(err.response.data.message);
    }
  };

  return (
    <div className='booking__cost__container'>
      <form onSubmit={submitPromoCodeHandler} className='w100 mb20 tal booking__promo-container'>
        { appliedDiscount ? (
          <div className="booking__promo--applied-discount">
            <FontAwesomeIcon className='booking__promo-close' icon={faClose} onClick={() => removeDiscountHandler()} />
            <div className='booking__promo-code'>{appliedDiscount * 100}% discount applied with <span className='bold'>{promoCode}</span>!</div>
          </div>
        ) : (
          <>
            <TextField id='text-field' label='Promo Code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} fullWidth sx={inputFocusBorderColorOverride} />
            <button className='call-to-action no-border' type='submit'>Apply</button>
          </>
        )}
      </form>
      { props.type === 'booking' || props.type === 'profile' ? (
        <div className='booking__cost'>
          Due before Appointment (HST Included): <span className='booking__cost--bold'>${(Math.ceil(((appliedDiscount ? +props.cost - (+props.cost * appliedDiscount) : +props.cost) * hst) * 100) / 100).toFixed(2)}</span>
        </div>
      ) : (
        <div className='booking__cost'>
          Cost with Discount (HST Included): <span className='booking__cost--bold'>${(Math.ceil(((appliedDiscount ? +props.cost - (+props.cost * appliedDiscount) : +props.cost) * hst) * 100) / 100).toFixed(2)}</span>
        </div>
      )}
    </div>
  );
}

export default BookingCost;
