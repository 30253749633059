import React from "react";

function Footer() {
  return (
    <footer className='footer-container'>
      <div className='left-nav'>© 2023 WellFit Gyms Inc.</div>
      <p className='poweredby'>
        POWERED BY
        <a className='arconic' href='https://arconic.io/' target='_blank'>
          ARCONIC
        </a>
      </p>
      <ul className='right-nav'>
        <li className='nav-link'>
          <a className="contact-link" href='mailto:support@wellfitgyms.com'>Need Help?</a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
