import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router';
import { AuthContext } from '../auth/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faCalendarDay, faClose, faFolder, faHome, faLock, faX } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer() {
  const navigate = useNavigate();
  const { auth, setAuth } = React.useContext(AuthContext);
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logoutHandler = () => {
    localStorage.removeItem('wellfit-authorization-jwt');
    localStorage.removeItem('wellfit-authorization-practitionerId');

    setAuth({
      auth: {
        isAuthenticated: false,
        practitionerId: '',
      },
      setAuth: null,
    });

    navigate('/login');
  };

  const list = (anchor: Anchor) => (
    <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100vw' }} role='presentation' onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {[
          { text: 'Home', to: '/home', icon: faHome },
          { text: 'Message Therapy', to: '/profile/megan-tsai', icon: faUser },
        ].map((text, index) => (
          <ListItem key={text.text} disablePadding>
            <ListItemButton>
              <FontAwesomeIcon className='sidedrawer-fa-icon' icon={text.icon!} />
              <ListItemText onClick={() => navigate(text.to)} classes={{ primary: 'font-size-override' }} primary={text.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          { text: 'Login', to: '/login', icon: faLock },
          { text: 'Close', icon: faX }
        ].map((text, index) => (
          <ListItem key={text.text} disablePadding>
            <ListItemButton>
              <FontAwesomeIcon className='sidedrawer-fa-icon' icon={text.icon!} />
              <ListItemText onClick={() => {
                if (text.to) {
                  navigate(text.to);
                } else {
                  setState({ ...state, [anchor]: null })
                }
              }} classes={{ primary: 'font-size-override' }} primary={text.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const authenticatedList = (anchor: Anchor) => (
    <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : '100vw' }} role='presentation' onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List>
        {[
          { text: 'Dashboard', to: '/dashboard', icon: faCalendarDay },
          { text: 'Public Profile', to: '/profile/megan-tsai', icon: faUser },
          { text: 'Reports', to: '/reports', icon: faFolder },
        ].map((text, index) => (
          <ListItem key={text.text} disablePadding>
            <ListItemButton>
              <FontAwesomeIcon className='sidedrawer-fa-icon' icon={text.icon!} />
              <ListItemText onClick={() => {
                if (text.to) {
                  navigate(text.to);
                } else {
                  setState({ ...state, [anchor]: null })
                }
              }} classes={{ primary: 'font-size-override' }} primary={text.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          { text: 'Logout', to: '/login', icon: faLock },
          { text: 'Close', icon: faX },
        ].map((text, index) => (
          <ListItem key={text.text} disablePadding>
            <ListItemButton>
              <FontAwesomeIcon className='sidedrawer-fa-icon' icon={text.icon!} />
              <ListItemText onClick={() => {
                if (text.text === 'Logout') {
                  logoutHandler();
                } else {
                  setState({ ...state, [anchor]: null })
                }
              }} classes={{ primary: 'font-size-override' }} primary={text.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {(['right'] as const).map(anchor => (
        <React.Fragment key={anchor}>
          <FontAwesomeIcon className='navbar-hamburger-icon' onClick={toggleDrawer(anchor, true)} icon={faBars} />
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {auth.isAuthenticated ? authenticatedList(anchor) : list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
