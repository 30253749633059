import axios, { AxiosResponse } from 'axios';

// PRODUCTION
const apiUrl = 'https://api.wellfitgyms.com/v1';

// STAGING
// const apiUrl = 'https://staging-api.wellfitgyms.com/api';

// LOCAL
// const apiUrl = 'http://localhost:4000/api';
// const apiUrl = process.env.REACT_APP_URI;

//  █████╗ ██╗   ██╗████████╗██╗  ██╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
// ███████║██║   ██║   ██║   ███████║
// ██╔══██║██║   ██║   ██║   ██╔══██║
// ██║  ██║╚██████╔╝   ██║   ██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝

export const betaUserSignUp = (email: string): Promise<AxiosResponse> => {
    const body = { email };
    return axios.post(`${apiUrl}/beta`, body, {
        withCredentials: false,
    });
}

export const login = (email: string, password: string): Promise<AxiosResponse> => {
    const body = { email, password };
    return axios.post(`${apiUrl}/auth/login/practitioner`, body, {
        withCredentials: false,
    });
}

// ██████╗  █████╗ ███████╗██╗  ██╗██████╗  ██████╗  █████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔══██╗██╔════╝██║  ██║██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██╔══██╗
// ██║  ██║███████║███████╗███████║██████╔╝██║   ██║███████║██████╔╝██║  ██║
// ██║  ██║██╔══██║╚════██║██╔══██║██╔══██╗██║   ██║██╔══██║██╔══██╗██║  ██║
// ██████╔╝██║  ██║███████║██║  ██║██████╔╝╚██████╔╝██║  ██║██║  ██║██████╔╝
// ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ 

export const getPractitioners = (): Promise<AxiosResponse> => {
    return axios.get(`${apiUrl}/practitioners`, {
        withCredentials: false,
    });
}

export const getPractitioner = (practitionerId: string): Promise<AxiosResponse> => {
    return axios.get(`${apiUrl}/practitioners/${practitionerId}`, {
        withCredentials: false,
    });
}

export const getPractitionerBookings = (practitionerId: string): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.get(`${apiUrl}/practitioners/${practitionerId}/bookings`, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false
    });
}

export const getReport = (startDate: string, endDate: string): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.get(`${apiUrl}/reports?startDate=${startDate}&endDate=${endDate}`, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false
    });
}

export const downloadReport = (report: string): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.post(`${apiUrl}/reports/download`, {report},{
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false
    });
}

// ██████╗ ██████╗  ██████╗ ███████╗██╗██╗     ███████╗
// ██╔══██╗██╔══██╗██╔═══██╗██╔════╝██║██║     ██╔════╝
// ██████╔╝██████╔╝██║   ██║█████╗  ██║██║     █████╗  
// ██╔═══╝ ██╔══██╗██║   ██║██╔══╝  ██║██║     ██╔══╝  
// ██║     ██║  ██║╚██████╔╝██║     ██║███████╗███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚══════╝╚══════╝

export const getPractitionerAvailableTimes = (practitionerId: string, timeCost: string, date: string = '', bookingId: string = ''): Promise<AxiosResponse> => {
    return axios.get(`${apiUrl}/practitioners/${practitionerId}/available-bookings?timeCost=${timeCost}&date=${date}&bookingId=${bookingId}`, {
        withCredentials: false,
    });
}

export const checkPromoCode = (practitionerId: string, promoCode: string): Promise<AxiosResponse> => {
    return axios.post(`${apiUrl}/practitioners/${practitionerId}/promos`, {
      promoCode,
    }, {
        withCredentials: false,
    });
}

export const createBooking = (payload: any): Promise<AxiosResponse> => {
    return axios.post(`${apiUrl}/bookings`, payload, {
        withCredentials: false,
    });
}

export const updateBooking = (payload: any, bookingId: string): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.put(`${apiUrl}/bookings/${bookingId}`, payload, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false,
    });
}

export const deleteBooking = (bookingId: string): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.delete(`${apiUrl}/bookings/${bookingId}`, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false,
    });
}

export const fetchPaymentLinkUrl = (bookingId: string): Promise<AxiosResponse> => {
  const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.get(`${apiUrl}/payments/${bookingId}`, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false,
    });
}

export const sendReceiptOrInvoiceToBooker = (bookingId: string): Promise<AxiosResponse> => {
  const token = window.localStorage.getItem('wellfit-authorization-jwt');
    return axios.get(`${apiUrl}/payments/send-attachments/${bookingId}`, {
        headers: { Authorization: `Bearer ${token}`},
        withCredentials: false,
    });
}