import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { fullWidthOverride, inputFocusBorderColorOverride } from '../styles/mui-tsx-overrides';
import { login } from '../services/http.service';
import { AuthContext } from '../auth/AuthContext';
import { AlertContext } from '../errors/AlertContext';

function Login() {
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  window.addEventListener('resize', () => setInnerWidth(window.innerWidth));

  let navigate = useNavigate();
  
  const { auth, setAuth } = useContext(AuthContext)!;
  const { alert, setAlert } = useContext(AlertContext);

  if (auth && auth?.isAuthenticated && auth.practitionerId) {
    navigate('/dashboard');
  }

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleEmailChange = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handlePasswordChange = (e: any) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const submit = async () => {
    try {
      const results: any = await login(email, password);
      window.localStorage.setItem('wellfit-authorization-jwt', results?.data?.token);
      window.localStorage.setItem('wellfit-authorization-practitionerId', results?.data?.data?.practitionerId);
      
      setAlert({
        alertText: '',
        alertType: '',
      });

      setAuth({
        isAuthenticated: true,
        practitionerId: results.data.data.practitionerId,
      });

      navigate('/dashboard');
      return results;
    } catch (err: any) {
      setAlert({
        alertText: err?.response?.data?.message || 'Authentication Failed',
        alertType: 'error',
      });

      setTimeout(() => {
        setAlert({
          alertText: '',
          alertType: '',
        });
      }, 5000);
    }
  };

  return (
    <div className='auth-container'>
      <Card className='auth-card' sx={{ minWidth: 275 }}>
        <div className='left-side'>
          <div className='image-container'>
            <img src={require('../assets/images/white-logo.png')} alt='WellFit Gyms Toronto' />
          </div>
          <div className='title'>Premium Fitness Space Rentals</div>
          <div>
            <ul className='list'>
              <li className='list-item'>Immersive Yoga</li>
              <span>●</span>
              <li className='list-item'>Cardio</li>
            </ul>
            <ul className='list'>
              <li className='list-item'>Cycling</li>
              <span>●</span>
              <li className='list-item'>Kickboxing</li>
              <span>●</span>
              <li className='list-item'>Strength Training</li>
            </ul>
          </div>
        </div>
        <div className='right-side'>
          <div className='greeting'>Welcome Back!</div>
          <TextField
            required
            id='text-field'
            label='Email'
            // defaultValue=""
            // value={email}
            onChange={handleEmailChange}
            sx={innerWidth <= 900 ? { ...inputFocusBorderColorOverride, ...fullWidthOverride } : { ...inputFocusBorderColorOverride }}
          />
          <FormControl id='password-form-control' variant='outlined' sx={inputFocusBorderColorOverride}>
            <InputLabel htmlFor='outlined-adornment-password'>Password *</InputLabel>
            <OutlinedInput
              fullWidth={innerWidth <= 900}
              sx={fullWidthOverride}
              required
              onChange={handlePasswordChange}
              id='password-field'
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
            />
          </FormControl>
          <Button fullWidth={innerWidth <= 900} onClick={submit} id='primary-button' variant='contained'>
            <span className='login-button-text'>Login</span>
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Login;
