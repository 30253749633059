import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { inputFocusBorderColorOverride } from '../styles/mui-tsx-overrides';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useScreenshot } from "use-screenshot-hook";
import { getReport } from '../services/http.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from '../components/Loader';

dayjs.extend(timezone);

function Reports() {
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [report, setReport] = React.useState<any>(null);
  const [totals, setTotals] = React.useState<any>(null);
  const screenshotRef = useRef<HTMLDivElement>(null);
  const {image, takeScreenshot} = useScreenshot({ ref: screenshotRef });
  const [loadingResponse, setLoadingResponse] = React.useState<boolean>(false);


  React.useEffect(() => {
    takeScreenshotHandler();
  }, [report]);

  useEffect(() => {
    setReport(null);
  }, [startDate, endDate]);

  const fetchPractitionerReport = async () => {
    setLoadingResponse(true);
    try {
      const results = await getReport(startDate, endDate);
      let totalPreTaxPaid: number = 0;
      let totalTaxPaid: number = 0;
      let totalPaid: number = 0;
      let totalPreTaxOwed: number = 0;
      let totalTaxOwed: number = 0;
      let totalOwed: number = 0;
      let totalWithoutDiscountPaid: number = 0;
      let totalWithDiscountPaid: number = 0;
      let totalWithoutDiscountOwed: number = 0;
      let totalWithDiscountOwed: number = 0;
      const totalTips: number = results?.data?.data?.bookings?.reduce((acc: number, booking: any) => {
        if (booking?.isPaid && booking?.tip) {
          acc += booking?.tip;
        }

        return acc;
      }, 0);

      for (const booking of results?.data?.data?.bookings) {
        if (booking?.isPaid) {
          totalPreTaxPaid += +booking?.preTax * 100;
          totalTaxPaid += +booking?.tax * 100;
          totalPaid += +booking?.total * 100;

          if (booking?.discount !== '0') {
            totalWithDiscountPaid += +booking?.preTax * 100;
          } else {
            totalWithoutDiscountPaid += +booking?.preTax * 100;
          }
        } else {
          totalPreTaxOwed += +booking?.preTax * 100;
          totalTaxOwed += +booking?.tax * 100;
          totalOwed += +booking?.total * 100;

          if (booking?.discount !== '0') {
            totalWithDiscountOwed += +booking?.preTax * 100;
          } else {
            totalWithoutDiscountOwed += +booking?.preTax * 100;
          }
        }
      }
      setTotals({
        totalPreTaxPaid,
        totalTaxPaid,
        totalPaid,
        totalPreTaxOwed,
        totalTaxOwed,
        totalOwed,
        totalWithoutDiscountPaid,
        totalWithDiscountPaid,
        totalWithoutDiscountOwed,
        totalWithDiscountOwed,
        totalTips,
      })
      setReport(results?.data?.data);
      setLoadingResponse(false);
      return results;
    } catch (err: any) {
      console.log('ERROR: ', err);
      setLoadingResponse(false);
    }
  };

  const takeScreenshotHandler = async () => {
    takeScreenshot('png');
  }
  
  const downloadPDFHandler = async () => {
    if (image) {
      let blob = await fetch(image, { mode: 'cors' }).then(r => r.blob());
      let objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.setAttribute('download', `wellfit-report-${startDate}${endDate ? '-' + endDate : ''}.png`);
      a.setAttribute('crossorigin', 'anonymous');
      a.setAttribute('target', '_blank');
      a.click();
      window.URL.revokeObjectURL(objectUrl);
    }
  };

  const handleStartDateChange = async (event: any) => {
    setStartDate(dayjs(event.$d).format('YYYY-MM-DD'));
  };

  const handleEndDateChange = async (event: any) => {
    setEndDate(dayjs(event.$d).format('YYYY-MM-DD'));
  };

  return (
    <div className='reports-container'>
      <div className='report-date-selectors'>
        <div className='report-date-picker-container'>
          <LocalizationProvider dateAdapter={AdapterDayjs} textFieldStyle={{ width: '100%' }}>
            <DatePicker label='Start Date' value={startDate} onChange={handleStartDateChange} renderInput={params => <TextField fullWidth id='text-field-date' {...params} sx={inputFocusBorderColorOverride} />} />
          </LocalizationProvider>
        </div>
        <div className='report-date-picker-container'>
          <LocalizationProvider dateAdapter={AdapterDayjs} textFieldStyle={{ width: '100%' }}>
            <DatePicker label='End Date' value={endDate} onChange={handleEndDateChange} renderInput={params => <TextField fullWidth id='text-field-date' {...params} sx={inputFocusBorderColorOverride} />} />
          </LocalizationProvider>
        </div>
          {startDate && endDate &&
            <div className='report-button-container'>
              <Button onClick={fetchPractitionerReport} id='primary-button' variant='contained'>
                <span className='button-text'>Generate Report</span>
              </Button>
            </div>
          }
      </div>
      {loadingResponse ? (
          <div className='booking-card__loader-container'>
            <Loader />
          </div>
        ) : null}
      {report &&
        <div className='outer-report-container'>
          <div className='download-container' onClick={downloadPDFHandler}>
            <div><FontAwesomeIcon icon={faDownload} className="download-button" /></div>
          </div>
          <div className='report-container' ref={screenshotRef}>
            <div>
              <div className="header">
                <div className="from">
                  <div>FROM</div>
                  <div className='title'>WellFit Gyms Inc.</div>
                  <div>Adrian Johansson</div>
                  <div>1 (289) 700-8145</div>
                  <div>support@wellfitgyms.com</div>
                  <div>www.wellfitgyms.com</div>
                </div>
                <div className="to">
                  <div>TO</div>
                  <div className='title'>{report?.practitioner?.name}</div>
                  <div>{`1 (${report?.practitioner?.phoneNumber?.slice(0, 3)}) ${report?.practitioner?.phoneNumber?.slice(3, 6)}-${report?.practitioner?.phoneNumber?.slice(6, 10)}`}</div>
                  <div>{report?.practitioner?.email}</div>
                </div>
              </div>
              <div className='table-title'>Detailed Appointment Breakdown ({report?.bookings?.length} items) - {startDate} {endDate !== startDate ? `- ${endDate}` : ''}</div>
              <table>
                <thead>
                  <tr>
                    <th className='date-col'>Date</th>
                    <th className='tal patient-col'>Patient</th>
                    <th>Service</th>
                    <th>Base Price</th>
                    <th>Discount</th>
                    <th>Discounted Cost</th>
                    <th>HST</th>
                    <th>Total</th>
                    <th>Tips</th>
                  </tr>
                </thead>
                <tbody>
                  {report?.bookings?.map((booking: any, i: number) => (
                    <tr key={i}>
                      <td className='date-col'>{booking?.startDate?.slice(0, 10)}</td>
                      <td className='tal bold patient-col'>{booking?.nameOfBooker}</td>
                      <td>{booking?.timeBlock?.name?.replace('minutes', 'Min.')} {booking?.service?.name}</td>
                      <td>${booking?.timeBlock?.cost?.toFixed(2)}</td>
                      <td>{booking?.discount * 100}%</td>
                      <td className={booking?.isPaid ? 'bold green' : 'bold red'}>${booking?.preTax}</td>
                      <td className={booking?.isPaid ? 'green' : 'red'}>${booking?.tax}</td>
                      <td className={booking?.isPaid ? 'bold green' : 'bold red'}>${booking?.total}</td>
                      <td>{booking?.tip ? `${(booking?.tip / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}` : '--'}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td className='tal bold'>TOTAL RECEIVED (<span className='green'>Green Values</span>)</td>
                    <td></td>
                    <td>--</td>
                    <td></td>
                    <td className='bold'>{totals?.totalPreTaxPaid ? `$${(totals?.totalPreTaxPaid / 100)?.toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTaxPaid ? `$${(totals?.totalTaxPaid / 100)?.toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPaid ? `$${(totals?.totalPaid / 100)?.toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTips ? `${(totals?.totalTips / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}` : '--'}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className='tal bold'>TOTAL PENDING (<span className='red'>Red Values</span>)</td>
                    <td></td>
                    <td>--</td>
                    <td></td>
                    <td className='bold'>{totals?.totalPreTaxOwed ? `$${(totals?.totalPreTaxOwed / 100)?.toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTaxOwed ? `$${(totals?.totalTaxOwed / 100)?.toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalOwed ? `$${(totals?.totalOwed / 100)?.toFixed(2)}` : '--'}</td>
                    <td>--</td>
                  </tr>
                </tbody>
              </table>
              <div className='table-title'>Final Summary</div>
              <table>
                <thead>
                  <tr>
                    <th className='tal'>Description</th>
                    <th>Items with no discount</th>
                    <th>Items with discount</th>
                    <th>Total before tax</th>
                    <th>HST</th>
                    <th>Total</th>
                    <th>Tips</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='tal bold'>TOTAL</td>
                    <td className='bold'>{totals?.totalWithoutDiscountPaid ? `$${(totals?.totalWithoutDiscountPaid / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalWithDiscountPaid ? `$${(totals?.totalWithDiscountPaid / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPreTaxPaid ? `$${(totals?.totalPreTaxPaid / 100).toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTaxPaid ? `$${(totals?.totalTaxPaid / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPaid ? `$${(totals?.totalPaid / 100).toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTips ? `${(totals?.totalTips / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}` : '--'}</td>
                  </tr>
                  <tr>
                    <td className='tal bold'>WELLFIT GYMS (20%)</td>
                    <td className='bold'>{totals?.totalWithoutDiscountPaid ? `$${(totals?.totalWithoutDiscountPaid * 0.2 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalWithDiscountPaid ? `$${(totals?.totalWithDiscountPaid * 0.2 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPreTaxPaid ? `$${(totals?.totalPreTaxPaid * 0.2 / 100).toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTaxPaid ? `$${(totals?.totalTaxPaid * 0.2 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPaid ? `$${(totals?.totalPaid * 0.2 / 100).toFixed(2)}` : '--'}</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td className='tal bold'>MEGAN TSAI (80%)</td>
                    <td className='bold'>{totals?.totalWithoutDiscountPaid ? `$${(totals?.totalWithoutDiscountPaid * 0.8 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalWithDiscountPaid ? `$${(totals?.totalWithDiscountPaid * 0.8 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPreTaxPaid ? `$${(totals?.totalPreTaxPaid * 0.8 / 100).toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTaxPaid ? `$${(totals?.totalTaxPaid * 0.8 / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold'>{totals?.totalPaid ? `$${(totals?.totalPaid * 0.8 / 100).toFixed(2)}` : '--'}</td>
                    <td>{totals?.totalTips ? `${(totals?.totalTips / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}` : '--'}</td>
                  </tr>
                  <tr>
                    <td className='tal bold red'>UNCOLLECTED PAYMENTS</td>
                    <td className='bold red'>{totals?.totalWithoutDiscountOwed ? `$${(totals?.totalWithoutDiscountOwed / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold red'>{totals?.totalWithDiscountOwed ? `$${(totals?.totalWithDiscountOwed / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold red'>{totals?.totalPreTaxOwed ? `$${(totals?.totalPreTaxOwed / 100).toFixed(2)}` : '--'}</td>
                    <td className='red'>{totals?.totalTaxOwed ? `$${(totals?.totalTaxOwed / 100).toFixed(2)}` : '--'}</td>
                    <td className='bold red'>{totals?.totalOwed ? `$${(totals?.totalOwed / 100).toFixed(2)}` : '--'}</td>
                    <td className="red">--</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Reports;