import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoutes() {
  let auth = localStorage.getItem('wellfit-authorization-jwt');
  let practitionerId = localStorage.getItem('wellfit-authorization-practitionerId');

  return (
    auth && practitionerId ? <Outlet /> : <Navigate to='/login' />
  )
}

export default PrivateRoutes;